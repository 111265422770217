<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('common.doctor') }} Login</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <div class="form-group row">
              <label
                for="example-text-input"
                class="col-4 col-form-label"
              >{{ $t('common.name') }}:</label>
              <div class="col-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="nama"
                  placeholder="Nama Dokter"
                  v-validate="'required'"
                  disabled
                  data-vv-as="Nama Dokter"
                  name="nama"
                  :class="{'is-invalid': errors.has('nama') }"
                />
                <div
                  v-show="errors.first('nama')"
                  class="invalid-feedback"
                >{{ errors.first('nama') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-4">
                <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="tambah-login"
                  id="tambah-login"
                  :value="true"
                  v-model="addUser">
                  <label class="form-check-label" for="tambah-login">
                    Tambah Login
                  </label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="ubah-login"
                    id="ubah-login"
                    :value="false"
                    v-model="addUser">
                  <label class="form-check-label" for="ubah-login">
                    Ubah User Login
                  </label>
                </div>
              </div>
            </div>
            <template v-if="addUser">
              <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Login:</label>
                  <div class="col-lg-6">
                      <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : isLoginLoading }'>
                      <input
                          type="text"
                          class="form-control"
                          v-model="user.login"
                          v-validate="'required|loginValid|max:50|uniqueLogin'"
                          data-vv-validate-on="blur"
                          data-vv-as="username"
                          name="username"
                          :class="{'is-invalid': errors.has('username') }"/>
                      <div v-show="errors.first('username')" class="invalid-feedback">{{ errors.first('username') }}</div>
                      </div>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Email:</label>
                  <div class="col-lg-6">
                      <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : isEmailLoading }'>
                      <input
                          type="text"
                          class="form-control"
                          v-model="user.email"
                          v-validate="'required|email|uniqueEmail'"
                          data-vv-validate-on="blur"
                          data-vv-as="Email"
                          name="email"
                          :class="{'is-invalid': errors.has('email') }"/>
                      <div v-show="errors.first('email')" class="invalid-feedback">{{ errors.first('email') }}</div>
                      </div>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Bahasa:</label>
                  <div class="col-lg-6">
                      <language-select
                          :changedValue.sync="user.langKey"
                          :id-select-picker="'langKey' + user.id"
                          :selected-picker="user.langKey"
                          :is-validate="false"
                      ></language-select>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Profil :</label>
                  <div class="col-lg-6">
                      <authorities-select
                          v-model="user.authorities"
                          :validation="'required'"
                          :multiple="true"
                      ></authorities-select>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-lg-4 col-form-label">User Grup :</label>
                  <div class="col-lg-6">
                      <user-group-select
                          v-model="user.userGroups"
                          :placeholder="'Pilih user group'"
                          :validation="'required'"
                          :multiple="true"
                      ></user-group-select>
                  </div>
              </div>
              <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Tenants :</label>
                    <div class="col-lg-6">
                       <vue-multi-select
                            v-model="user.tenantUsers"
                            :options="listTenants"
                            :validation="'required'"
                            :label="'name'"
                            :name="'SelectTenants'"
                            :trackBy="'id'"
                            :multiple="true"
                        >
                        </vue-multi-select>
                    </div>
                </div>
              <div v-if="!user.id">
                  <div class="form-group row">
                      <label class="col-lg-4 col-form-label">Password:</label>
                      <div class="col-lg-6">
                          <input
                              type="password"
                              class="form-control"
                              v-model="user.password"
                              v-validate="'required|min:4|max:50'"
                              data-vv-as="Password"
                              ref="password"
                              name="password"
                              :class="{'is-invalid': errors.has('password') }"/>
                          <div v-show="errors.first('password')" class="invalid-feedback">{{ errors.first('password') }}</div>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label class="col-lg-4 col-form-label">Konfirmasi Password:</label>
                      <div class="col-lg-6">
                          <input
                              type="password"
                              class="form-control"
                              v-validate="'required|confirmed:password'"
                              data-vv-as="Konfirmasi Password"
                              name="pass_conf"
                              :class="{'is-invalid': errors.has('pass_conf') }"/>
                          <div v-show="errors.first('pass_conf')" class="invalid-feedback">{{ errors.first('pass_conf') }}</div>
                      </div>
                  </div>
              </div>
            </template>
            <template v-else>
              <vue-multi-select
                v-model="selectedValue"
                placholder="Cari User"
                open-direction="bottom"
                :options="optionsUser"
                :custom-label="customLabel"
                label="login"
                :loading="searchLoading"
                track-by="login"
                :searchable="true"
                :search-change="searchUser"
                :clear-on-select="false"
                :close-on-select="false"
              ></vue-multi-select>
              <div class="mt-2" v-if="selectedValue">
                <label for="data-user-dokter">Data User Login</label>
                <div class=" form-group row">
                  <label class="col-lg-4 col-form-label">User Login :</label>
                  <div class="col-lg-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="selectedValue.login"
                    disabled
                  />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Nama :</label>
                  <div class="col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="selectedValue.firstName"
                      disabled
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Email :</label>
                  <div class="col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="selectedValue.email"
                      disabled
                    />
                  </div>
                </div>
            </div>
            </template>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >{{ $t('button.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user");
import User from "../../../model/user-model";
import { Validator } from 'vee-validate';
import LanguageSelect from "../../_select/Language";
import AuthoritiesSelect from "../../_select/Authorities";
import UserGroupSelect from "../../_select/GroupUser";
const SweetAlert = Vue.extend(SweetAlertTemplate);
import VueMultiSelect from '../../_select/VueMultiSelect';
import DoctorRepository from '../../../repositories/DoctorRepository';


export default {
  components: {
    LanguageSelect,
    AuthoritiesSelect,
    UserGroupSelect,
    VueMultiSelect
  },
  data() {
    return {
      id: null,
      nama: "",
      date: "",
      user : new User(),
      isLoaded :  false,
      isLoginLoading: false,
      tempUserLogin : null,
      tempEmailLogin : null,
      isEmailLoading: false,
      addUser: true,
      selectedValue: '',
      optionsUser: [],
      searchLoading: false,
      dokter: null,
      listTenants: []
    };
  },
  props: {
    modalName: { type: String, default: 'modal_doctor' },
    modalData: {}
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          if (!vx.addUser) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data User akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(vx.selectedValue);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data User akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.create(vx.user);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          }
          return;
        }
      });
    },
    async update(newUser) {
      var vx = this;
      let payload = {
        dokterId: vx.id,
        userId: newUser.id
      }
      await DoctorRepository.changeUserId(payload)
        .then(({ data }) => {
          new Promise((resolve, reject) => {
           vx.$emit("event", resolve);
           resolve()
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data User berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          var msg = ValidateErrorMessage.parseMessage(error);
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: msg,
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
      // console.log(payload, vx.user, ' klasjdlkashdlkjahsdkh');
      // await UserRepository.updateUser(payload)
      //   .then(response => {
      //     new Promise((resolve, reject) => {
      //       vx.$emit("event", resolve);
      //     }).then((response) => {
      //       let instance = new SweetAlert().showInfo({
      //         swalTitle: "Berhasil!",
      //         swalText: "Perubahan data User berhasil disimpan.",
      //         swalType: "success",
      //         onConfirmButton: function () {
      //           blockUI.unblockModal();
      //           vx.clearData();
      //         }
      //       });
      //     });
      //   })
      //   .catch(error => {
      //     var msg = ValidateErrorMessage.parseMessage(error);
      //     let instance = new SweetAlert().showInfo({
      //       swalTitle: "Gagal!",
      //       swalText: msg,
      //       swalType: "error",
      //       onConfirmButton: function () {
      //         blockUI.unblockModal();
      //       }
      //     });
      //   });
    },
    async create(payload) {
      var vx = this;
      payload.dokterId = vx.id //assign dokter id
      await UserRepository.createUser(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
            resolve()
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data User berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          var msg = ValidateErrorMessage.parseMessage(error);
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: msg,
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    showToastError: function(message) {
        toastr.error(message);
    },
    checkLogin: function(value, resolve){
        var params = 'login%3A'+value;
        UserRepository.search(params).then((response) => {
            this.isLoginLoading = false;
            if (response.data.length > 0) {
                return resolve({
                    valid: false,
                    data: {
                    message: `Login ${value} sudah terpakai`
                    }
                });
            } else {
                return resolve({
                    valid: true
                });
            }

        }).catch(err => {
            this.isLoginLoading = false;
            var msg = (error.message) ? error.message : error;
            this.showToastError('check login : ' + msg);
            return resolve({
                valid: false,
                data : {
                    message: `Gagal Validasi Check Login`
                }
            })
        })
    },
    checkEmail: function(value, resolve){
        const split = value.split('@');
        var params = 'email%3A'+split[0];
        UserRepository.search(params).then((response) => {
            this.isEmailLoading = false;
            const temp = response.data.filter(x => x.email.toLowerCase() == value.toLowerCase());
            if (temp.length > 0) {
                return resolve({
                    valid: false,
                    data: {
                    message: `Email ${value} sudah terpakai`
                    }
                });
            } else {
                return resolve({
                    valid: true
                });
            }

        }).catch(err => {
            this.isEmailLoading = false;
            var msg = (error.message) ? error.message : error;
            this.showToastError('check email : ' + msg);
            return resolve({
                valid: false,
                data : {
                    message: `Gagal Validasi Check Email`
                }
            })
        })
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    updateUserData: async function (data) {
      const response = await UserRepository.getUser(data.login)
      this.user = response.data;
      this.tempUserLogin = this.user.login;
      this.tempEmailLogin = this.user.email;
      this.selectedValue = this.user
      this.optionsUser = [this.user]
      this.isLoaded = true;
    },
    updateData: async function (doctor) {
      let vx = this;
      vx.id = doctor.id;
      vx.nama = doctor.nama;
      if(doctor.jadwals.length > 0) {
        $.each(doctor.jadwals, function(key, value){
          $.each(value.jam, function(key2, value2){
            vx.dokter = value2.dokter
          })
        })
      }
      
      if(vx.dokter && vx.dokter.userId){
        vx.addUser = false
        const { data } = await UserRepository.getUserById(vx.dokter.userId)
        vx.updateUserData(data)
      } else {
        const response = await UserRepository.fetch();
        vx.optionsUser = response.data;
      }
    },
    searchUser: _.debounce(function (query) {
      let vx = this;
      if(query.length > 3){
        vx.searchLoading = true
        UserRepository.search(query)
          .then(({ data }) => {
            vx.optionsUser = data
            vx.searchLoading = false
          })
          .catch(e => {
            vx.optionsUser = []
            vx.showToastError('Data tidak ditermukan')
            vx.searchLoading = false
          })
      }
    }, 500),
    customLabel({ login, email}){
      return `${login} - ${email}`
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);
    }
    this.listTenants = JSON.parse(localStorage.tenants)
    const isUniqueLogin = value =>
        new Promise(resolve => {
            this.errors.remove('login');
            this.isLoginLoading = true
            //
            if(this.user.id){
                if(value == this.tempUserLogin) {
                    this.isLoginLoading = false;
                    return resolve({
                        valid: true
                    });
                } else {
                    this.checkLogin(value, resolve);
                }
            } else {
                this.checkLogin(value, resolve);
            }
        });

    const isEmailUnique = value =>
        new Promise(resolve => {
            this.errors.remove('email');
            this.isEmailLoading = true;
            if(this.user.id){
                if(value.toLowerCase() == this.tempEmailLogin.toLowerCase()){
                    this.isEmailLoading = false;
                    return resolve({
                        valid: true
                    });
                } else {
                    this.checkEmail(value, resolve);
                }
            } else {
                this.checkEmail(value, resolve);
            }
        })
    Validator.extend("uniqueLogin", {
      validate: isUniqueLogin,
      getMessage: (field, params, data) => data.message
    });
    Validator.extend("uniqueEmail", {
      validate: isEmailUnique,
      getMessage: (field, params, data) => data.message
    });
    Validator.extend('loginValid', {
        getMessage: field => 'Login hanya dapat berisi huruf, angka dan karakter spesial seperti: . _ -  ',
        validate: value => {
           const regex = value.match(/^[_.A-Za-z0-9-]*$/g);
           return ( regex == null ) ? false : true;
        }
    });
  }
};
</script>