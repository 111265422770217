export default function User() {
    return {
        id: null,
        login: null,
        firstName: null,
        lastName: null,
        email: null,
        activated: false,
        langKey: null,
        authorities: [],
        createdBy: null,
        createdDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        password: null,
        userGroups: [],
        tenantUsers: []
    };
}
